









import { DtoReportingForm, reqNewReporting } from "@/api";
import ReportMenu from "../components/ReportMenu.vue";
import Uploader from "../components/Uploader.vue";
import { Component, Vue } from "vue-property-decorator";
import Reporting from "./Reporting.vue";

@Component({
  name: "anonymous",
  components: {
    Uploader,
    ReportMenu,
    Reporting
  }
})
export default class extends Vue {
  form: DtoReportingForm = {};

  async initNewReporting() {
    const { data } = await reqNewReporting();
    this.form = data;
    this.form.reportingWay = "ANONYMOUS_REPORT";
    await this.$nextTick();
    (this.$refs.reportor as Reporting).initValid();
  }

  mounted() {
    this.initNewReporting();
  }
}
